/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/it/view/2022/09/786949/"
  }, "네이버 타임아웃 오류로 한때 접속장애…\"태풍 영향은 아냐\" - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.donga.com/news//article/all/20220906/115340086/1"
  }, "네이버 번역기 ‘파파고’서 장애 발생…“정기배포 과정서 오류 포착” - www.donga.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ncloud.com/support/notice/all/1411"
  }, "네이버 클라우드 플랫폼 간헐적 접속 장애 완료 안내 (9/5 10:54 ~ 11:25)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developers.naver.com/forum/posts/33584"
  }, "internet server error 500 or 502\t- 개발자 포럼 - NAVER Developers")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.digitaltoday.co.kr/news/articleView.html?idxno=460813"
  }, "추석연휴에 내비 '티맵' 먹통...\"넷플릭스법 적용 대상 늘려야\"- 디지털투데이")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/iphone-14-pro/"
  }, "iPhone 14 Pro and iPhone 14 Pro Max - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/apple-watch-ultra/"
  }, "Apple Watch Ultra - Apple")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
